import { gql } from '@apollo/client';

export const getResources = gql`
  query GetResources {
    resource {
      id
      name
      metadata
      createdAt: created_at
      updatedAt: updated_at
      services {
        id
        service_id
        options
        service {
          id
          title
        }
      }
    }
  }
`;

export const addServiceToResource = gql`
  mutation AddServiceToResource($data: resource_service_insert_input!) {
    insert_resource_service_one(
      object: $data
      on_conflict: {
        constraint: resource_service_resource_id_service_id_key
        update_columns: [service_id, options]
      }
    ) {
      id
      options
      service {
        id
        title
      }
    }
  }
`;

export const removeServiceFromResource = gql`
  mutation RemoveService($resource: uuid!, $service: uuid!) {
    delete_resource_service(
      where: { resource_id: { _eq: $resource }, service_id: { _eq: $service } }
    ) {
      affected_rows
    }
  }
`;

export const resourceAvailibilty = gql`
  query ResourceAvailibility(
    $id: uuid!
    $start: timestamptz!
    $end: timestamptz!
  ) {
    resource: resource_by_pk(id: $id) {
      id
      name
      services {
        id
        service {
          title
        }
        options
        service_id
      }
      entities(
        order_by: { start: asc }
        where: { _and: [{ start: { _gte: $start } }, { end: { _lte: $end } }] }
      ) {
        start
        end
        resource_id
      }
    }
  }
`;

export const updateResourceService = gql`
  mutation UpdateResourceService($id: uuid!, $options: jsonb) {
    update_resource_service_by_pk(
      pk_columns: { id: $id }
      _set: { options: $options }
    ) {
      id
      options
      service {
        id
        title
      }
    }
  }
`;
