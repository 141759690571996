import { gql } from '@apollo/client';

import {
  Call,
  Client,
  Lead,
  LeadContact,
  LeadConversion,
  Note,
} from './fragments';

export const getLeads = gql`
  ${Lead}
  query GetLeads(
    $limit: Int
    $offset: Int
    $sort: [lead_order_by!]
    $status: [String!]
  ) {
    leads: lead(
      limit: $limit
      offset: $offset
      order_by: $sort
      where: { status: { _in: $status } }
    ) {
      ...Lead
      messages: lead_contacts {
        id
      }
      conversions: lead_conversions {
        id
      }
    }
    agg: lead_aggregate(where: { status: { _in: $status } }) {
      aggregate {
        count
      }
    }
  }
`;

export const clientLeads = gql`
  ${Lead}
  query ClientLeads($id: uuid!) {
    leads: lead(order_by: { created_at: desc }, where: { user: { _eq: $id } }) {
      ...Lead
      # messages: lead_contacts {
      #   id
      # }
      # conversions: lead_conversions {
      #   id
      # }
    }
  }
`;

export const getLeadById = gql`
  ${Lead}
  ${Client}
  ${Note}
  ${LeadContact}
  ${LeadConversion}
  ${Call}
  query Lead($id: uuid!) {
    lead: lead_by_pk(id: $id) {
      ...Lead
      calls {
        ...Call
        created_at
        id
        updatedAt: updated_at
        createdAt: created_at
        payload
        user_id
        status
        user {
          id
          title
          name: given_name
          surname: family_name
          fullName: full_name
          email
          phoneNumber: contact_number
          avatar
          createdAt: created_at
          updatedAt: updated_at
        }
      }
      user: userByUser {
        ...Client
        labels: user_labels(order_by: { created_at: desc }) {
          label {
            id
            name
          }
        }
        complaints {
          id
          created_at
          updated_at
          title
          complaint
          resolution
          assignee {
            id
            user {
              id
              name: given_name
              surname: family_name
              fullName: full_name
              assets: user_assets {
                id
                assetByAsset {
                  id
                  url
                }
              }
            }
          }
        }
        appointments(order_by: { updated_at: desc }) {
          id
          entities(limit: 1, order_by: { start: asc_nulls_last }) {
            start
            service: serviceByService {
              title
            }
          }
        }
      }
      notes: lead_notes {
        id
        data: noteByNote {
          ...Note
        }
      }
      messages: lead_contacts(order_by: { created_at: desc }) {
        ...LeadContact
        userByWho {
          id
          name: given_name
        }
      }
      lead_contacts_aggregate {
        aggregate {
          count
        }
      }
      conversions: lead_conversions {
        ...LeadConversion
        user: user {
          id
          name: given_name
          surname: family_name
          fullName: full_name
        }
        appointment: appointmentByAppointment {
          entities {
            service: serviceByService {
              title
            }
            employee
            start
            end
          }
        }
      }
    }
  }
`;

export const updateLeadById = gql`
  mutation updateLeadById($id: uuid!, $data: lead_set_input!) {
    update_lead_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const updateLeadStatus = gql`
  ${Lead}
  ${Note}
  ${LeadContact}
  mutation UpdateLeadStatus($id: uuid!, $status: String!) {
    update_lead_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      ...Lead
      notes: lead_notes {
        id
        data: noteByNote {
          ...Note
        }
      }
      messages: lead_contacts(order_by: { created_at: desc }) {
        ...LeadContact
        userByWho {
          id
          name: given_name
        }
      }
      lead_contacts_aggregate {
        aggregate {
          count
        }
      }
      lead_notes_aggregate {
        aggregate {
          count
        }
      }
      conversions: lead_conversions {
        id
        value
        createdAt: created_at
        user: user {
          id
          name: given_name
          surname: family_name
          fullName: full_name
        }
        appointment: appointmentByAppointment {
          entities {
            service: serviceByService {
              title
            }
            employee
            start
            end
          }
        }
      }
    }
  }
`;

export const updateLeadsUser = gql`
  ${Lead}
  ${Note}
  ${LeadConversion}
  mutation UpdateLead($id: uuid!, $user: uuid!) {
    update_lead_by_pk(pk_columns: { id: $id }, _set: { user: $user }) {
      ...Lead
      notes: lead_notes {
        id
        data: noteByNote {
          ...Note
        }
      }
      conversions: lead_conversions {
        ...LeadConversion
        user: user {
          id
          name: given_name
          surname: family_name
          fullName: full_name
        }
        appointment: appointmentByAppointment {
          entities {
            service: serviceByService {
              title
            }
            employee
            start
            end
          }
        }
      }
    }
  }
`;

export const addLeadNote = gql`
  ${Note}
  mutation LeadNote($data: lead_note_insert_input!) {
    insert_lead_note_one(object: $data) {
      id
      note: noteByNote {
        ...Note
      }
    }
  }
`;

export const addLeadContact = gql`
  ${LeadContact}
  mutation LeadContact($data: lead_contact_insert_input!) {
    insert_lead_contact_one(object: $data) {
      ...LeadContact
    }
  }
`;

export const addLeadConversion = gql`
  ${LeadConversion}
  mutation LeadConversion($data: lead_conversion_insert_input!) {
    insert_lead_conversion_one(object: $data) {
      ...LeadConversion
    }
  }
`;

export const getIncomingStatus = gql`
  query IncomingStatus {
    status: lead_status {
      value
    }
  }
`;

export const getAllLeads = gql`
  query GetLeads {
    leads: lead(order_by: { updated_at: desc_nulls_last }) {
      id
      due
      content
      createdAt: created_at
      metadata
      source
      updatedAt: updated_at
      user
      status
      lead_notes_aggregate {
        aggregate {
          count
        }
      }
      lead_contacts_aggregate {
        aggregate {
          count
        }
      }
      lead_conversions_aggregate {
        aggregate {
          count
        }
      }
      # messages: lead_contacts {
      #   id
      # }
      # conversions: lead_conversions {
      #   id
      # }
    }
  }
`;

export const addDefinedMessage = gql`
  mutation addDefinedMessage($data: defined_messages_insert_input!) {
    insert_defined_messages_one(object: $data) {
      id
      title
      content
      type
    }
  }
`;

export const getDefinedMessages = gql`
  query getDefinedMessage {
    defined_messages {
      id
      created_at
      updated_at
      title
      content
      type
    }
  }
`;

export const updateMessage = gql`
  mutation updateMessage($id: uuid!, $data: defined_messages_set_input!) {
    update_defined_messages_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;
export const deleteMessage = gql`
  mutation deleteMessage($id: uuid!) {
    delete_defined_messages(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
