// import 'utils/wdyr'
import '@stripe/terminal-js';
import Amplify from 'aws-amplify';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { Suspense } from 'react';

import Loader from 'components/atoms/loader';

import CallProvider from 'providers/calls';
import SessionProvider, { useSession } from 'providers/session';

import '../styles/index.css';

const OrganizationProvider = dynamic(() => import('providers/organization'), {
  suspense: true,
});
const UIProvider = dynamic(() => import('providers/ui'), { suspense: true });
const BaseProvider = dynamic(() => import('providers/base'), {
  suspense: true,
});

const isProduction = process.env.NODE_ENV === 'production';

Amplify.configure({
  aws_project_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_COGNITO_ID_POOL,
});

export function reportWebVitals(metric) {
  // @ts-ignore
  window.gtag &&
    window.gtag('event', metric.name, {
      event_category:
        metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(
        metric.name === 'CLS' ? metric.value * 1000 : metric.value
      ),
      event_label: metric.id,
      non_interaction: true,
    });
}

const pathWhitelist = [
  '/sign-in',
  '/register',
  '/forgot-password',
  '/reset-password',
];

function Internal({ Component, pageProps }) {
  const session = useSession();
  const router = useRouter();
  const role = session.claims && session.claims['x-hasura-role'];

  if (
    (session.user && pathWhitelist.includes(router.pathname)) ||
    router.pathname === '/'
  ) {
    router.push('/dashboard');
  }

  return (
    // @ts-ignore
    <OrganizationProvider>
      <BaseProvider>
        {/* @ts-ignore */}
        <UIProvider>
          <CallProvider>
            <Component {...pageProps} />
          </CallProvider>
        </UIProvider>
      </BaseProvider>
    </OrganizationProvider>
  );

  return (
    // @ts-ignore
    <OrganizationProvider>
      <BaseProvider>
        {/* @ts-ignore */}
        <UIProvider>
          <Component {...pageProps} />
        </UIProvider>
      </BaseProvider>
    </OrganizationProvider>
  );
}

function GetManaged({ Component, pageProps }) {
  return (
    <>
      <Head>
        {/* <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        /> */}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Head>
      <Script strategy="lazyOnload" src="https://js.stripe.com/v3/" id="s-js" />
      <Script
        strategy="lazyOnload"
        id="t-js"
        src="https://js.stripe.com/terminal/v1/"
      />
      <Script
        strategy="afterInteractive"
        id="g-js"
        src="https://www.googletagmanager.com/gtag/js?id=G-MDHKS63X41"
      />
      {isProduction && (
        <>
          <Script
            id="h-js"
            strategy="afterInteractive"
          >{` window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid = e, window.heap.config = t = t || {};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        heap.load("982808672");
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-MDHKS63X41');
        `}</Script>
          <Script id="cls-js" strategy="afterInteractive">
            {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "mth4y86qcb");`}
          </Script>
        </>
      )}

      <Suspense fallback={<Loader />}>
        <SessionProvider Component={Component} pageProps={pageProps}>
          <Internal Component={Component} pageProps={pageProps} />
        </SessionProvider>
      </Suspense>
    </>
  );
}

export default GetManaged;
