import { gql } from '@apollo/client';

export const shortServices = gql`
  query Service {
    service(order_by: { title: desc }) {
      id
      title
      pricing {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              active
              label
              price
              was
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
    }
  }
`;

export const getAvailableServices = gql`
  query getAvailableServices {
    services: service(where: { archived: { _eq: false } }) {
      id
      title
      subtitle
      content
      attributes
      options
      createdAt: created_at
      updatedAt: updated_at
      media {
        id
        asset: assetByAsset {
          id
          props
          url
          type: assetType {
            value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(where: { pricingByPricing: { active: { _eq: true } } }) {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          active
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              active
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`;

export const getServices = gql`
  query Service {
    service(order_by: { title: asc }) {
      id
      title
      subtitle
      content
      attributes
      options
      archived
      stripe_id
      createdAt: created_at
      updatedAt: updated_at
      media(order_by: { created_at: desc }) {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          active
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              discount
              active
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`;

export const getServiceById = gql`
  query Service($id: uuid!) {
    service: service_by_pk(id: $id) {
      id
      title
      subtitle
      content
      attributes
      options
      createdAt: created_at
      updatedAt: updated_at
      media {
        id
        asset: assetByAsset {
          id
          props
          url
          type: assetType {
            value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(where: { pricingByPricing: { active: { _eq: true } } }) {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          active
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              active
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`;

export const updateService = gql`
  mutation UpdateService($id: uuid!, $data: service_set_input!) {
    service: update_service_by_pk(_set: $data, pk_columns: { id: $id }) {
      id
      title
      attributes
      content
      archived
    }
  }
`;

export const findServices = gql`
  query FindService($term: String!) {
    services: service(where: { _or: [{ title: { _ilike: $term } }] }) {
      id
      title
      subtitle
      pricing {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          active
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              active
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
    }
  }
`;

export const addService = gql`
  mutation AddService($data: service_insert_input!) {
    insert_service_one(object: $data) {
      id
      serviceMetadata {
        id
      }
      media {
        asset
      }
    }
  }
`;

export const addPricing = gql`
  mutation AddPricing($data: pricing_insert_input!) {
    insert_pricing_one(object: $data) {
      id
      price
      discount
      was
      attributes
      label
    }
  }
`;

export const addServicePricing = gql`
  mutation AddServicePricing($data: service_pricing_insert_input!) {
    insert_service_pricing_one(object: $data) {
      service
      pricing
    }
  }
`;

export const addServicePhoto = gql`
  mutation AddServicePhoto($data: service_media_insert_input!) {
    insert_service_media_one(object: $data) {
      id
    }
  }
`;

export const deleteServiceMedia = gql`
  mutation deleteServiceMedia($assetID: uuid!, $serviceID: uuid!) {
    delete_service_media(
      where: {
        _and: { asset: { _eq: $assetID }, service: { _eq: $serviceID } }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const getAttributes = gql`
  query getAttributes {
    attributes {
      name
    }
  }
`;

export const insertAttribute = gql`
  mutation createAttribute($data: attributes_insert_input!) {
    insert_attributes_one(object: $data) {
      name
    }
  }
`;

export const deleteAttribute = gql`
  mutation deleteAttribute($name: String!) {
    delete_attributes(where: { name: { _eq: $name } }) {
      returning {
        name
      }
    }
  }
`;
