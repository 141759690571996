import { captureException } from '@sentry/nextjs';

export function resizedataURL(datas, wantedWidth, wantedHeight) {
  return new Promise(async function (resolve, reject) {
    // We create an image to receive the Data URI
    var img = document.createElement('img');
    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;
      // We resize the image with the canvas method drawImage();
      // @ts-ignore
      ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
      var dataURI = canvas.toDataURL();
      // This is the return of the Promise
      resolve(dataURI);
    };
    // We put the Data URI in the image's src attribute
    img.src = datas;
  });
}

export const toBase64 = (str) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

export const shimmer = (w, h) => `
    <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient id="g">
          <stop stop-color="#fcfcfc" offset="20%" />
          <stop stop-color="#ebebeb" offset="50%" />
          <stop stop-color="#fcfcfc" offset="70%" />
        </linearGradient>
      </defs>
      <rect width="${w}" height="${h}" fill="#fcfcfc" />
      <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
      <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
    </svg>`;

export const genLink = async (target: string, open = true) => {
  try {
    const part = target.split('/private')[1];
    const req = await fetch(`/api/link?key=${part}`);
    const { url } = await req.json();
    if (url) {
      if (open) {
        window.open(url, '_blank');
      } else {
        return url;
      }
    }
  } catch (err) {
    console.log(err);
    captureException(err);
  }
};

export const prepareImage = (src: string, token: string) => {
  // console.log('prepareImage', src);
  const s = src.split('/private');
  return `https://assets.withmanaged.com/private${s[1]}?token=${token}`;
};
