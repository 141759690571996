import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client';
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getOrganization, getTeam } from 'queries/org';
import { getServices } from 'queries/services';

import { Organization, Service, Team, TeamMember } from '../interfaces';
import { useSession } from './session';

export interface OrganizationContextValue {
  organization: Organization | undefined;
  team: TeamMember[] | undefined;
  services: Service[] | undefined;
  teamMember: (id: string) => TeamMember | undefined;
  loadServices: (
    options?: QueryLazyOptions<Record<string, any>> | undefined
  ) => void;
  servicesCalled: boolean;
  loadTeam: (options?: QueryLazyOptions<OperationVariables>) => void;
}

const initialState = {
  team: undefined,
};

export const OrganizationContext = createContext(
  initialState as OrganizationContextValue
);

interface State {
  team: TeamMember[] | undefined;
}

export const OP: FC = ({ children }: { children: React.ReactNode }) => {
  const { claims } = useSession();
  const [state, setState] = useState<State>({
    ...initialState,
  });

  const [loadTeam, { data, called: teamCalled }] = useLazyQuery<Team>(getTeam, {
    fetchPolicy: 'cache-and-network',
  });

  const [loadOrganization, { data: organization, called: orgCalled }] =
    useLazyQuery<{ organization: Organization }>(getOrganization, {
      variables: {
        organization: claims && claims['x-hasura-organization-id'],
      },
      fetchPolicy: 'cache-and-network',
    });

  const [loadServices, { data: serviceData, called: servicesCalled }] =
    useLazyQuery<{
      service: Service[];
    }>(getServices, {
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    const org = claims && claims['x-hasura-organization-id'];
    if (org) {
      if (!teamCalled) {
        loadTeam({
          variables: {
            organization: claims && claims['x-hasura-organization-id'],
          },
        });
      }
      if (!orgCalled) {
        loadOrganization({
          variables: {
            organization: claims && claims['x-hasura-organization-id'],
          },
        });
      }
    }
  }, []);

  const teamMember = useCallback(
    (id: string) => {
      if (!data) return undefined;
      return data.team.find((x) => x.user.id === id);
    },
    [data]
  );

  const value: OrganizationContextValue = useMemo(
    () => ({
      ...state,
      organization: organization && organization.organization,
      team: data && data.team,
      services: serviceData && serviceData.service,
      teamMember,
      loadServices,
      servicesCalled,
      loadTeam,
    }),
    [
      data,
      loadServices,
      loadTeam,
      organization,
      serviceData,
      servicesCalled,
      state,
      teamMember,
    ]
  );

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const OrganizationProvider = OP;

export const useOrganization = () => useContext(OrganizationContext);

export default OrganizationProvider;
