import { gql } from '@apollo/client';

import { Client } from './fragments';

export const getOrganization = gql`
  query GetOrganization($organization: uuid!) {
    organization: organization_by_pk(id: $organization) {
      id
      name
      active
      vatNumber
      billing_id
      companyNumber
      createdAt: created_at
      updatedAt: updated_at
      addresses {
        id
        addressByAddress {
          address_id: id
          data
        }
      }
      organization_billing {
        id
        customer_id
        plan_id
        subscription_id
        trial
        trial_ends
      }
      billing {
        id
        customer_id
        plan_id
        subscription_id
        trial
        trial_ends
      }
    }
  }
`;

export const getTeam = gql`
  ${Client}
  query Team {
    team: organization_member(order_by: { active: asc_nulls_last }) {
      id
      active
      jobTitle: job_title
      jobType: job_type
      startDate: start_date
      endDate: end_date
      color
      options
      user {
        ...Client
        memberAvatar: user_assets(limit: 1, order_by: { created_at: desc }) {
          asset: assetByAsset {
            id
            url
          }
        }
        services {
          id: service
          data: serviceByService {
            title
          }
        }
        call_session {
          id
          token
          status
        }
      }
    }
  }
`;

export const getAvailibility = gql`
  subscription Availability($start: date!, $end: date!, $employees: [uuid!]) {
    availability: schedule(
      where: {
        _and: [
          { available: { _eq: true } }
          { date: { _gte: $start } }
          { date: { _lte: $end } }
          { user: { _in: $employees } }
        ]
      }
    ) {
      id
      date
      start
      end
      available
      week: week_number
      day: day_of_week
      resource {
        id
        name
      }
      member: organization_member {
        id: member
        user {
          id
        }
      }
    }
  }
`;

export const getAvailibilityQuery = gql`
  query Availability($month: Int!, $year: Int!) {
    availability: schedule(
      where: {
        _and: [
          { available: { _eq: true } }
          { year: { _eq: $year } }
          { month: { _eq: $month } }
        ]
      }
    ) {
      id
      date
      start
      end
      available
      week: week_number
      day: day_of_week
      resource {
        id
        name
      }
      member: organization_member {
        id: member
      }
    }
  }
`;

export const loadSchedule = gql`
  query LoadSchedule($start: date!, $end: date) {
    schedule(
      where: { _and: [{ date: { _gte: $start } }, { date: { _lte: $end } }] }
      order_by: { date: desc_nulls_last }
    ) {
      id
      available
      date
      is_leave
      start
      end
      day: day_of_week
      user
      resource {
        id
        name
        metadata
      }
      week: week_number
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getFinancials = gql`
  query OrgFinancials($start: timestamptz, $end: timestamptz) {
    booked: payment_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $start } }
          { created_at: { _lte: $end } }
          { status: { _eq: "complete" } }
        ]
      }
    ) {
      aggregate {
        count(columns: amount)
        sum {
          amount
          discount
        }
        avg {
          amount
          discount
        }
        max {
          amount
          discount
        }
      }
    }
    bookings: appointment_aggregate(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      aggregate {
        count
      }
    }
    services: appointment_entity_aggregate(
      where: {
        _and: [
          { start: { _gte: $start } }
          { start: { _lte: $end } }
          {
            _or: [
              { status: { _eq: course_session } }
              { status: { _eq: new } }
              { status: { _eq: completed } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    totalUsers: user_aggregate {
      aggregate {
        count
      }
    }
    users: user_aggregate(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      aggregate {
        count
      }
    }
    leads: lead_aggregate(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      aggregate {
        count
      }
    }
    leadConversions: lead_conversion_aggregate(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getActivity = gql`
  query OrgActivity($start: timestamptz, $end: timestamptz) {
    appointment(
      where: {
        _and: [
          { created_at: { _gte: $start } }
          { created_at: { _lte: $end } }
          { status: { _neq: pending } }
          { status: { _neq: deleted } }
        ]
      }
      order_by: { created_at: desc }
      limit: 20
    ) {
      id
      status
      client: userByUser {
        id
        name: given_name
        surname: family_name
        email
        phoneNumber: contact_number
      }
      entities {
        id
        start
        end
        buffer
        resourceId: resource_id
        employee
        updatedBy: updated_by
        documents: appointmentByAppointment {
          documents {
            complete
          }
          reports {
            id
            status
          }
        }
        option: pricing {
          id
          label
          active
          attributes
          price
        }
        subOption: pricingByPricingSubOption {
          id
          label
          active
          price
        }
        service: serviceByService {
          id
          title
          category: categoryByCategory {
            id
            name
          }
        }
        status: appointment_status {
          value: status
        }
      }
    }
  }
`;

export const memberStats = gql`
  query MemberStats($user: uuid!, $start: timestamptz, $end: timestamptz) {
    serviceBookings: payment_aggregate(
      where: {
        _and: [
          {
            appointment_payments: {
              appointmentByAppointment: {
                entities: {
                  _and: [
                    { employee: { _eq: $user } }
                    { created_at: { _gte: $start } }
                    { created_at: { _lte: $end } }
                    # { status: { _eq: completed } }
                  ]
                }
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        bookings: count
        sales: sum {
          amount
        }
        discounts: sum {
          discount
        }
      }
    }
  }
`;

export const getEmployeeRotaDaysRemaining = gql`
  query getEmployeeRotaDaysRemaining($id: uuid!, $start: date!, $end: date) {
    schedule_aggregate(
      where: {
        _and: [
          { organization_member: { member: { _eq: $id } } }
          { available: { _eq: true } }
          { date: { _gte: $start } }
          { date: { _lte: $end } }
          { organization_member: { active: { _eq: true } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getTargetsForUserID = gql`
  query getTargetsForUserID($user_id: uuid!) {
    organization_target(
      where: { user_id: { _eq: $user_id } }
      order_by: { date: asc }
    ) {
      id
      date
      value
      type
    }
  }
`;

export const updateTargetByID = gql`
  mutation updateTargetByID($id: uuid!, $data: organization_target_set_input!) {
    update_organization_target_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const addTarget = gql`
  mutation addTask($data: organization_target_insert_input!) {
    insert_organization_target_one(object: $data) {
      id
    }
  }
`;

export const paymentsInPeriod = gql`
  query Payments($start: timestamptz, $end: timestamptz) {
    payments: payments_in_period_with_method(
      args: { start: $start, end: $end }
    ) {
      count
      date
      organization
      amount
      method
    }
  }
`;

export const getConsultationConversions = gql`
  query ConsultationConversions($month: Int!, $year: Int! = 2023) {
    consultation_conversion(args: { month: $month, year: $year }) {
      commission
      employee
      given_name
      total
    }
  }
`;

export const getProductsConversions = gql`
  query ProductsConversions($month: Int!, $year: Int! = 2023) {
    product_conversion(args: { month: $month, year: $year }) {
      commission
      employee
      given_name
      total
    }
  }
`;
