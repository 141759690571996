import { gql } from '@apollo/client';

import {
  Address,
  Appointment,
  AppointmentEntity,
  Asset,
  Client,
  Note,
  Order,
  Payment,
} from './fragments';

export const createClient = gql`
  ${Client}
  mutation CreateClient($data: user_insert_input!) {
    client: insert_user_one(object: $data) {
      ...Client
    }
  }
`;

export const getClients = gql`
  ${Client}
  query Clients {
    clients: user(order_by: { given_name: asc }) {
      ...Client
    }
  }
`;

export const getClient = gql`
  ${Client}
  query Client($id: uuid!) {
    client: user(where: { id: { _eq: $id } }, limit: 1) {
      ...Client
    }
  }
`;

export const getClientByContactNumber = gql`
  ${Client}
  query getClientByContactNumber($contactNumber: String!) {
    user(where: { contact_number: { _eq: $contactNumber } }) {
      ...Client
    }
  }
`;

export const clientAppointments = gql`
  query ClientAppointments($id: uuid!) {
    appointments: appointment(
      where: {
        _and: [
          { user: { _eq: $id } }
          { status: { _neq: deleted } }
          { status: { _neq: pending } }
        ]
      }
      order_by: { updated_at: desc }
    ) {
      id
      appointmentId: id
      who
      updatedBy: updated_by
      bookedBy: booked_by
      metadata
      source
      order {
        id
        data
        createdAt: created_at
      }
      status: appointmentStatus {
        value: status
      }
      services: entities(
        where: { appointment_status: { status: { _neq: "DELETED" } } }
        order_by: { start: asc }
      ) {
        id
        start
        end
        employee
        resource: resource_id
        who
        service: serviceByService {
          id
          title
        }
        option: pricing {
          id
          label
          price
          attributes
          type
          active
          discount
        }
        subOption: pricingByPricingSubOption {
          id
          label
          price
          attributes
          type
          active
          discount
        }
        status: appointment_status {
          value: status
        }
        createdAt: created_at
        updatedAt: updated_at
      }
      notes: appointment_notes(order_by: { noteByNote: { updated_at: desc } }) {
        note: noteByNote {
          id
          title
          content
          createdAt: created_at
          updatedAt: updated_at
        }
      }
      payments {
        data: paymentByPayment {
          id
          transaction_id
          discount
          amount
          order_id
          createdAt: created_at
        }
      }
      notifications {
        id
        type
        entity: appointment_entity {
          id
          start
          service: serviceByService {
            id
            title
          }
        }
        createdAt: created_at
      }
      createdAt: created_at
      updatedAt: updated_at
      metadata
    }
  }
`;

export const clientAppointmentById = gql`
  ${Appointment}
  ${AppointmentEntity}
  ${Order}
  ${Note}
  ${Payment}
  query ClientAppointment($id: uuid!) {
    appointment: appointment_by_pk(id: $id) {
      ...Appointment
      order {
        ...Order
      }
      documents {
        id
        asset
        complete
        createdAt
        updatedAt
        form: formByForm {
          id
          title
        }
      }
      completeAppointments: entities(
        where: { status: { _eq: completed } }
        limit: 1
      ) {
        id
      }
      services: entities(
        where: { appointment_status: { status: { _neq: "DELETED" } } }
        order_by: { start: asc }
      ) {
        ...AppointmentEntity
      }
      notes: appointment_notes(order_by: { noteByNote: { updated_at: desc } }) {
        note: noteByNote {
          ...Note
        }
      }
      payments {
        appointment
        data: paymentByPayment {
          ...Payment
        }
      }
      createdAt: created_at
      updatedAt: updated_at
      metadata
    }
  }
`;

export const clientAddresses = gql`
  ${Address}
  query ClientAddresses($id: uuid!) {
    addresses: user_address(
      where: { user: { _eq: $id } }
      order_by: { addressByAddress: { updatedAt: desc } }
    ) {
      address: addressByAddress {
        ...Address
      }
    }
  }
`;

export const clientStats = gql`
  query ClientStats($id: uuid!) {
    appointments: appointment_entity_aggregate(
      where: { appointmentByAppointment: { user: { _eq: $id } } }
    ) {
      data: aggregate {
        total: count
      }
    }
    payments: payment_aggregate(where: { user: { _eq: $id } }) {
      data: aggregate {
        total: sum {
          amount
        }
      }
    }
    outstanding: appointment_entity(
      where: { appointmentByAppointment: { user: { _eq: $id } } }
    ) {
      pricing {
        price
      }
      pricingByPricingSubOption {
        price
      }
    }
  }
`;

export const insertClientAsset = gql`
  ${Asset}
  mutation InsertClientAsset($data: user_asset_insert_input!) {
    insert_user_asset_one(object: $data) {
      id
      asset: assetByAsset {
        ...Asset
      }
    }
  }
`;

export const insertClientAssets = gql`
  ${Asset}
  mutation InsertClientAssets($data: [user_asset_insert_input!]!) {
    insert_user_asset(objects: $data) {
      returning {
        id
        asset: assetByAsset {
          ...Asset
        }
      }
    }
  }
`;

export const updateUserProperty = gql`
  ${Client}
  mutation UpdateUserProperty($id: uuid!, $user: user_set_input!) {
    update_user(where: { id: { _eq: $id } }, _set: $user) {
      returning {
        ...Client
      }
    }
  }
`;

export const findClient = gql`
  ${Client}
  query FindClient($term: String!) {
    clients: user(
      where: {
        _or: [
          { given_name: { _ilike: $term } }
          { family_name: { _ilike: $term } }
          { email: { _ilike: $term } }
        ]
      }
    ) {
      ...Client
    }
  }
`;

export const getClientData = gql`
  query ClientPayments($id: uuid!, $now: timestamptz) {
    payments: payment_aggregate(where: { user: { _eq: $id } }) {
      aggregate {
        count(columns: amount)
        sum {
          amount
        }
      }
    }
    nextAppointment: appointment_entity(
      where: {
        _and: [
          { appointmentByAppointment: { user: { _eq: $id } } }
          { start: { _gt: $now } }
        ]
      }
      limit: 1
    ) {
      start
    }
    lastAppointment: appointment_entity(
      where: {
        _and: [
          { appointmentByAppointment: { user: { _eq: $id } } }
          { start: { _lt: $now } }
        ]
      }
      limit: 1
    ) {
      start
    }
    labels: user_labels(where: { user_id: { _eq: $id } }) {
      id
      description
      color
      label {
        id
        name
      }
    }
  }
`;

export const clientOrders = gql`
  query clientOrders($id: uuid!) {
    orders: order(where: { user: { _eq: $id } }) {
      id
      created_at
      updated_at
      total
      status
      current_total
      appointments {
        id
        appointmentId: id
        who
        updatedBy: updated_by
        bookedBy: booked_by
        metadata
        source
        order {
          id
          data
          createdAt: created_at
        }
        status: appointmentStatus {
          value: status
        }
        services: entities(
          where: { appointment_status: { status: { _neq: "DELETED" } } }
          order_by: { start: asc }
        ) {
          id
          start
          end
          employee
          resource: resource_id
          who
          service: serviceByService {
            id
            title
          }
          option: pricing {
            id
            label
            price
            attributes
            type
            active
            discount
          }
          subOption: pricingByPricingSubOption {
            id
            label
            price
            attributes
            type
            active
            discount
          }
          status: appointment_status {
            value: status
          }
          createdAt: created_at
          updatedAt: updated_at
        }
        notes: appointment_notes(
          order_by: { noteByNote: { updated_at: desc } }
        ) {
          note: noteByNote {
            id
            title
            content
            createdAt: created_at
            updatedAt: updated_at
          }
        }
        payments {
          data: paymentByPayment {
            id
            transaction_id
            discount
            amount
            createdAt: created_at
          }
        }
        notifications {
          id
          type
          entity: appointment_entity {
            id
            start
            service: serviceByService {
              id
              title
            }
          }
          createdAt: created_at
        }
        createdAt: created_at
        updatedAt: updated_at
        metadata
      }
    }
  }
`;

export const clientNotes = gql`
  ${Note}
  query ClientNotes($id: uuid!) {
    user: user_by_pk(id: $id) {
      id
      appointments {
        id
        notes: appointment_notes(
          order_by: { noteByNote: { updated_at: desc } }
        ) {
          id
          appointment: appointmentByAppointment {
            id
            entities {
              start
              service: serviceByService {
                title
              }
            }
          }
          note: noteByNote {
            ...Note
          }
        }
      }
      general: user_notes(order_by: { noteByNote: { updated_at: desc } }) {
        id
        note: noteByNote {
          ...Note
        }
      }
    }
  }
`;

export const createNote = gql`
  ${Note}
  mutation AddNote($data: user_note_insert_input!) {
    insert_user_note_one(object: $data) {
      id
      note: noteByNote {
        ...Note
      }
    }
  }
`;

export const updateNote = gql`
  ${Note}
  mutation UpdateNote($id: uuid!, $title: String!, $content: String!) {
    update_note_by_pk(
      pk_columns: { id: $id }
      _set: { title: $title, content: $content }
    ) {
      ...Note
    }
  }
`;

export const addClientPhoto = gql`
  ${Asset}
  mutation AddClientPhoto($data: asset_insert_input!) {
    insert_asset_one(object: $data) {
      ...Asset
    }
  }
`;

export const updateClientPhoneNumber = gql`
  ${Client}
  mutation UpdateClientPhoneNumber($id: uuid!, $phoneNumber: String!) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { contact_number: $phoneNumber }
    ) {
      ...Client
    }
  }
`;

export const updateClientDetails = gql`
  ${Client}
  mutation UpdateClientDetails(
    $id: uuid!
    $given_name: String
    $family_name: String
    $phoneNumber: String
    $email: String
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        given_name: $given_name
        family_name: $family_name
        contact_number: $phoneNumber
        email: $email
      }
    ) {
      ...Client
    }
  }
`;

export const updateClient = gql`
  ${Client}
  mutation UpdateClientDetails($id: uuid!, $data: user_set_input!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $data) {
      ...Client
    }
  }
`;

export const clientByAuthId = gql`
  ${Client}
  query client($id: String!) {
    user(where: { cognito_id: { _eq: $id } }) {
      ...Client
    }
  }
`;

export const getTopClients = gql`
  ${Client}
  ${Payment}
  query getTopClients {
    user(
      limit: 100
      order_by: { payments_aggregate: { sum: { amount: desc_nulls_last } } }
    ) {
      appointments_aggregate {
        aggregate {
          numberOfBookings: count
        }
      }
      ...Client
      payments(limit: 1, order_by: { created_at: desc }) {
        ...Payment
        appointment_payments {
          appointmentByAppointment {
            id
            created_at
          }
        }
      }
      payments_aggregate {
        aggregate {
          sum {
            totalSpent: amount
            totalDiscount: discount
          }
        }
      }
    }
  }
`;

// New Profile Queries

export const clientPurchases = gql`
  query UserPurchases($id: uuid!) {
    productsSales: product_sale(
      where: { client_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      id
      appointmentId: appointment_id
      delivery
      orderId: order_id
      price
      quantity
      employee: user_id
      appointment {
        status
        createdAt: created_at
        updatedAt: updated_at
        updated_by
      }
      product {
        id
        title
        cost_price
        archived
        media {
          id
          assetByAsset {
            id
            type
            url
          }
        }
      }
      createdAt: created_at
    }
    payments: payment(
      where: { user: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      id
      amount
      method
      employee
      status
      order_id
      transactionId: transaction_id
      createdAt: created_at
      credits {
        id
        credit {
          id
          amount
          explanation
          employee_id
        }
      }
      discounts {
        id
        discount {
          id
          code
          type
          value
        }
      }
      paymentItems {
        id
        amount
        productSale {
          id
          price
          quantity
          product {
            id
            title
          }
        }
        service {
          id
          title
        }
        pricing {
          id
          label
        }
        pricingOption {
          id
          label
        }
      }
    }
  }
`;

export const clientAssets = gql`
  ${Asset}
  query ClientAssets($id: uuid!) {
    assets: user_asset(
      where: { user: { _eq: $id } }
      order_by: { assetByAsset: { updatedAt: desc } }
    ) {
      id
      asset: assetByAsset {
        ...Asset
      }
    }
  }
`;

export const clientLabelsById = gql`
  query ClientLabels($id: uuid!) {
    user: user_by_pk(id: $id) {
      id
      labels: user_labels {
        id
        description
        color
        label {
          id
          name
        }
      }
    }
  }
`;

export const getAllClientLabels = gql`
  query GetAllLabels {
    labels {
      id
      created_at
      updated_at
      name
      description
    }
  }
`;

export const insertClientLabel = gql`
  mutation InsertUserLabel($data: user_labels_insert_input!) {
    insert_user_labels_one(object: $data) {
      id
      created_at
      updated_at
    }
  }
`;

export const removeClientLabel = gql`
  mutation RemoveUserLabel($labelId: uuid!, $userId: uuid!) {
    delete_user_labels(
      where: {
        label_id: { _eq: $labelId }
        _and: { user_id: { _eq: $userId } }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const createClientLabel = gql`
  mutation CreateLabel($name: String!, $description: String) {
    insert_labels_one(object: { name: $name, description: $description }) {
      id
      created_at
      updated_at
      name
      description
    }
  }
`;

export const updateClientLabel = gql`
  mutation UpdateLabel($id: uuid!, $data: user_labels_set_input!) {
    update_user_labels_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const getLabelsByName = gql`
  query GetLabelByName($name: String!) {
    labels(where: { name: { _eq: $name } }) {
      id
      name
    }
  }
`;
