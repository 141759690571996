import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  concat,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/nextjs';
import { captureException } from '@sentry/nextjs';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { createClient } from 'graphql-ws';
import haversine from 'haversine-distance';
import Cookies from 'js-cookie';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Logo from '../assets/logo.svg';

export interface ExtendedUser extends CognitoUser {
  imageUrl?: string;
  attributes: {
    [key: string]: any;
  };
}

interface State {
  client: ApolloClient<NormalizedCacheObject> | null;
  loading: boolean;
  user?: ExtendedUser;
  type?: string;
  token?: string;
  claims?: { [key: string]: string };
  role?: string;
  validLocation: boolean;
  ip?: string | null;
}

const initialState = {
  loading: true,
};

export interface SessionContextValue {
  client: ApolloClient<NormalizedCacheObject>;
  loading: boolean;
  user?: ExtendedUser;
  type?: string;
  token?: string;
  claims?: { [key: string]: string };
  role?: string;
  validLocation: boolean;
  setContext: React.Dispatch<React.SetStateAction<State>>;
  logout: () => void;
  getRole: () => string | null;
}

export const SessionContext = createContext(
  initialState as SessionContextValue
);

const currentUser = async () => {
  try {
    const data = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (!data) {
      return {};
    }

    const { idToken } = data.signInUserSession;
    const type = idToken.payload['cognito:groups'][0];

    const claims =
      idToken.payload && idToken.payload['https://hasura.io/jwt/claims']
        ? JSON.parse(idToken.payload['https://hasura.io/jwt/claims'])
        : {};

    const token = idToken.jwtToken;

    const userData = {
      user: data,
      claims,
      type,
      token,
      role: claims && claims['x-hasura-role'],
    };

    try {
      Sentry.configureScope(function (scope) {
        const u = {
          id: data.attributes.sub,
          email: data.attributes.email,
        };
        scope.setUser(u);
      });
    } catch (err) {
      console.log(err);
      captureException(err);
    }

    return userData;
  } catch (err) {
    captureException(err);
    console.log('User not authenticated');
    if (window.location.pathname !== '/sign-in') {
      window.location.href = '/sign-in';
    }
    return {};
  }
};

const whitelist = ['charmelle@charmelle.london'];
const ipWhitelist = [
  '85.255.232.218',
  '82.6.42.181',
  '62.31.29.226',
  '85.255.233.21',
  '86.142.52.44',
];

export const SP = ({ Component, pageProps, children }) => {
  const [state, setState] = useState<State>({
    client: null,
    ...initialState,
    validLocation: true,
    ip: null,
  });

  const [links, setLinks] = useState({
    ws: null,
  });

  useEffect(() => {
    async function start() {
      const userData = await currentUser();
      // @ts-ignore
      if (userData && userData.token) {
        // @ts-ignore
        Cookies.set('mgdtoken', userData.token, {
          domain: `.${window.location.hostname}`,
        });
        setState((s) => ({
          ...s,
          ...userData,
        }));
      } else {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }

      try {
        fetch('https://www.cloudflare.com/cdn-cgi/trace')
          .then((r) => r.text())
          .then((r) => {
            let data = r
              .trim()
              .split('\n')
              .reduce(function (obj, pair) {
                // @ts-ignore
                pair = pair.split('=');
                return (obj[pair[0]] = pair[1]), obj;
              }, {});
            // @ts-ignore
            if (data.ip) {
              // @ts-ignore
              setState((s) => ({ ...s, ip: data.ip }));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    }

    start().catch(console.error);

    function success(position) {
      if (position.coords) {
        console.log(position.coords);
        const clinicDistance = haversine(position.coords, {
          latitude: 51.4099677,
          longitude: 0.0098616,
        });
        const collegeDistance = haversine(position.coords, {
          latitude: 51.3661557,
          longitude: 0.0517364,
        });
        console.log('clinicDistance', clinicDistance);
        console.log('collegeDistance', collegeDistance);

        const validLocation = clinicDistance < 250 || collegeDistance < 250;
        setState((s) => ({ ...s, validLocation }));
      }
    }

    function error(e) {
      console.log(e);
      console.log('Sorry, no position available.');
      Sentry.captureException(e);
    }

    const options = {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000,
    };

    const watchID = navigator.geolocation.watchPosition(
      success,
      error,
      options
    );

    return () => {
      navigator.geolocation.clearWatch(watchID);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      const cache = new InMemoryCache({});

      const headers = {
        // @ts-ignore
        authorization: `Bearer ${state.token}`,
      };

      const ws = new GraphQLWsLink(
        createClient({
          url: process.env.NEXT_PUBLIC_API_URL.replace('https://', 'wss://'),
          connectionParams: () => {
            // Note: getSession() is a placeholder function created by you
            if (!state.token) {
              return {};
            }
            return {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            };
          },
        })
      );

      const newClient = new ApolloClient({
        link: logoutLink.concat(
          concat(
            new ApolloLink((operation, forward) => {
              // @ts-ignore
              if (state.token) {
                operation.setContext({
                  headers,
                });
              }
              return forward(operation);
            }),
            ws
              ? split(
                  ({ query }) => {
                    const definition = getMainDefinition(query);
                    return (
                      definition.kind === 'OperationDefinition' &&
                      definition.operation === 'subscription'
                    );
                  },
                  ws,
                  new HttpLink({
                    credentials: 'include',
                    uri: process.env.NEXT_PUBLIC_API_URL,
                  })
                )
              : new HttpLink({
                  credentials: 'include',
                  uri: process.env.NEXT_PUBLIC_API_URL,
                })
          )
        ),
        cache,
      });

      setLinks({
        ws,
      });

      setState((s) => ({
        ...s,
        loading: false,
        client: newClient,
      }));
    };

    if (state.token && state.user) {
      init().catch(console.error);
    }
  }, [state.token, state.user]);

  const logout = useCallback(async () => {
    try {
      await Auth.signOut();
      Cookies.remove('mgdtoken');
      setTimeout(() => (window.location.href = '/sign-in'), 500);
    } catch (err) {
      console.log(err);
      captureException(err);
    }
  }, []);

  const logoutLink = onError(({ networkError }) => {
    if (
      networkError &&
      // @ts-ignore
      networkError.statusCode &&
      // @ts-ignore
      networkError.statusCode === 401
    ) {
      currentUser()
        .then((user) => {
          setState((s) => ({ ...s, ...user, loading: false }));
        })
        .catch((err) => {
          console.log(err);
          setState((s) => ({ ...s, user: null, loading: false }));
        });
    }
  });

  const getRole = useCallback(() => {
    if (state.claims) {
      return state.claims['x-hasura-role'];
    }
    return null;
  }, [state.claims]);

  // @ts-ignore
  const value: SessionContextValue = useMemo(
    () => ({
      ...state,
      logout,
      getRole,
      setContext: setState,
    }),
    [state, logout, getRole]
  );

  const locationBypassCheck = true;
  // (!state.user && !state.loading) ||
  // (state.ip && ipWhitelist.includes(state.ip)) ||
  // (state.user &&
  //   state.user.attributes.email &&
  //   whitelist.includes(state.user.attributes.email));

  return (
    <SessionContext.Provider value={value}>
      {state.loading ? (
        <div className="bg-gray-50 flex flex-col justify-center items-center w-screen h-screen">
          <Logo className="mx-auto w-auto max-w-xs" />
        </div>
      ) : null}
      {!state.loading &&
      !state.user &&
      (locationBypassCheck || state.validLocation) ? (
        <Component {...pageProps} />
      ) : null}
      {!state.loading &&
      state.user &&
      state.client &&
      (locationBypassCheck || state.validLocation) ? (
        <ApolloProvider client={state.client}>{children}</ApolloProvider>
      ) : null}
    </SessionContext.Provider>
  );
};

export const SessionProvider = SP;

export const useSession = () => useContext(SessionContext);

export default SessionProvider;
