import { gql } from '@apollo/client';

import {
  AppointmentEntity,
  Asset,
  Client,
  Form,
  Note,
  Payment,
  PaymentItem,
  Response,
} from './fragments';

export const newAppointment = gql`
  mutation NewAppointment($data: [appointment_insert_input!]!) {
    insert_appointment(objects: $data) {
      returning {
        id
        client: userByUser {
          id
          name: given_name
          surname: family_name
          fullName: full_name
        }
        status: appointmentStatus {
          value: status
        }
        services: entities {
          id
          start
          end
          buffer
          employee
          resource: resource_id
          option: pricing {
            id
            label
            price
            active
          }
          subOption: pricingByPricingSubOption {
            id
            label
            price
            active
          }
          service: serviceByService {
            id
            title
          }
          status: appointment_status {
            value: status
          }
        }
      }
    }
  }
`;

export const getAppointments = gql`
  query AppointmentsByMonthEmployee($month: Int!, $year: Int!) {
    appointment(
      where: {
        _and: [
          { status: { _neq: pending } }
          {
            entities: {
              _and: [
                { month: { _eq: $month } }
                { year: { _eq: $year } }
                {
                  _or: [
                    { status: { _eq: new } }
                    { status: { _eq: course_session } }
                    { status: { _eq: completed } }
                  ]
                }
              ]
            }
          }
        ]
      }
    ) {
      id
      client: user
      services: entities(
        where: {
          _and: [
            { month: { _eq: $month } }
            { year: { _eq: $year } }
            {
              _or: [
                { status: { _eq: new } }
                { status: { _eq: course_session } }
                { status: { _eq: completed } }
              ]
            }
          ]
        }
      ) {
        id
        start
        end
        buffer
        employee
        confirmed_by
        resource: resource_id
        service: serviceByService {
          id
          title
          category: categoryByCategory {
            id
            name
          }
        }
        status: appointment_status {
          value: status
        }
      }
    }
  }
`;

export const getAppointmentsSubscription = gql`
  subscription AppointmentsByMonth($week: Int!, $year: Int!) {
    appointment(
      where: {
        _and: [
          { status: { _neq: pending } }
          {
            entities: {
              _and: [
                { week_number: { _eq: $week } }
                { year: { _eq: $year } }
                {
                  _or: [
                    { status: { _eq: new } }
                    { status: { _eq: course_session } }
                    { status: { _eq: completed } }
                  ]
                }
              ]
            }
          }
        ]
      }
    ) {
      id
      client: user

      services: entities(
        where: {
          _and: [
            { week_number: { _eq: $week } }
            { year: { _eq: $year } }
            {
              _or: [
                { status: { _eq: new } }
                { status: { _eq: course_session } }
                { status: { _eq: completed } }
              ]
            }
          ]
        }
      ) {
        id
        start
        end
        buffer
        confirmed_by
        resourceId: resource_id
        employee
        updatedBy: updated_by
        documents: appointmentByAppointment {
          documents {
            complete
          }
          reports {
            id
            status
          }
        }
        option: pricing {
          id
          label
          active
          attributes
        }
        subOption: pricingByPricingSubOption {
          id
          label
          active
        }
        service: serviceByService {
          id
          title
          category: categoryByCategory {
            id
            name
          }
        }
        status: appointment_status {
          value: status
        }
      }
    }
  }
`;

export const getAppointmentById = gql`
  ${Asset}
  ${Payment}
  ${PaymentItem}
  ${Note}
  ${Client}
  ${Response}
  ${Form}
  query AppointmentById($id: uuid!) {
    appointment: appointment_by_pk(id: $id) {
      id
      metadata
      status: appointmentStatus {
        value: status
      }
      source
      client: userByUser {
        ...Client
      }
      documents {
        ...Response
        formData: formByForm {
          ...Form
        }
      }
      services: entities(
        order_by: { start: asc }
        where: { status: { _neq: deleted } }
      ) {
        id
        start
        end
        buffer
        who
        employee
        resourceId: resource_id
        service: serviceByService {
          id
          title
          category: categoryByCategory {
            id
            name
          }
        }
        option: pricing {
          id
          label
          price
          attributes
          type
          active
          discount
        }
        subOption: pricingByPricingSubOption {
          id
          label
          price
          attributes
          type
          active
          discount
        }
        status: appointment_status {
          value: status
        }
        createdAt: created_at
        updatedAt: updated_at
      }
      notes: appointment_notes(order_by: { noteByNote: { updated_at: desc } }) {
        id
        note: noteByNote {
          ...Note
        }
      }
      payments {
        id
        data: paymentByPayment {
          ...Payment
          paymentItems {
            ...PaymentItem
            productSale {
              product_id
              quantity
            }
          }
        }
      }
      photos: appointment_assets {
        id
        asset: assetByAsset {
          ...Asset
        }
      }
      notifications {
        id
        type
        entity
        metadata
        createdAt: created_at
      }
      products {
        id
        price
        quantity
        product {
          id
          title
          media(where: { assetByAsset: { type: { _eq: IMAGE } } }) {
            data: assetByAsset {
              id
              type
              url
            }
          }
        }
        employee {
          id
          name: given_name
        }
        createdAt: created_at
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const deleteAppointmentByID = gql`
  mutation deleteAppointmentByID($id: uuid!) {
    delete_appointment_by_pk(id: $id) {
      id
    }
  }
`;

export const updateAppointmentEntityById = gql`
  ${AppointmentEntity}
  mutation UpdateAppointmentEntity(
    $id: uuid!
    $data: appointment_entity_set_input!
  ) {
    entity: update_appointment_entity_by_pk(
      pk_columns: { id: $id }
      _set: $data
    ) {
      ...AppointmentEntity
    }
  }
`;

export const addAppointmentNote = gql`
  ${Note}
  mutation AppointmentNote($data: appointment_note_insert_input!) {
    insert_appointment_note_one(object: $data) {
      id
      note: noteByNote {
        ...Note
      }
    }
  }
`;

export const updateAppointmentNote = gql`
  ${Note}
  mutation UpdateNote($id: uuid!, $content: String!) {
    update_note_by_pk(pk_columns: { id: $id }, _set: { content: $content }) {
      ...Note
    }
  }
`;

export const addAppointmentPhoto = gql`
  ${Asset}
  mutation AddAppointmentPhoto($data: asset_insert_input!) {
    insert_asset_one(object: $data) {
      ...Asset
    }
  }
`;

export const checkoutEntity = gql`
  mutation CheckoutEntity(
    $entityId: uuid!
    $entity: appointment_entity_set_input
  ) {
    update_appointment_entity_by_pk(
      pk_columns: { id: $entityId }
      _set: $entity
    ) {
      id
      start
      end
      buffer
      employee
      resource: resource_id
      service: serviceByService {
        id
        title
      }
      option: pricing {
        id
        label
        price
        attributes
        type
        active
        discount
      }
      subOption: pricingByPricingSubOption {
        id
        label
        price
        attributes
        type
        active
        discount
      }
      status: appointment_status {
        value: status
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const appointmentStatus = gql`
  mutation AppointmentStatus($id: uuid!, $status: appointment_set_input) {
    update_appointment_by_pk(pk_columns: { id: $id }, _set: $status) {
      id
    }
  }
`;

export const newAppointmentPayment = gql`
  ${Payment}
  mutation AppointmentPayment($data: payment_insert_input!) {
    insert_payment_one(object: $data) {
      ...Payment
    }
  }
`;

export const deleteAppointmentEntityByID = gql`
  mutation deleteAppointmentEntityByID($id: uuid!) {
    delete_appointment_entity_by_pk(id: $id) {
      id
    }
  }
`;

export const deleteAppointmentEntities = gql`
  mutation deleteAppointmentEntities($data: appointment_entity_bool_exp!) {
    delete_appointment_entity(where: $data) {
      affected_rows
    }
  }
`;

export const deleteEntitiesByAppointmentId = gql`
  mutation deleteEntitiesByAppointmentId($appointmentId: uuid) {
    delete_appointment_entity(where: { appointment: { _eq: $appointmentId } }) {
      affected_rows
    }
  }
`;

export const addEntityToAppointment = gql`
  mutation addEntityToAppointment($data: appointment_entity_insert_input!) {
    insert_appointment_entity_one(object: $data) {
      id
    }
  }
`;

export const addAppointment = gql`
  mutation insertAppointment($data: appointment_insert_input!) {
    appointment: insert_appointment_one(object: $data) {
      id
      entities(order_by: { created_at: desc }, limit: 1) {
        id
        service: serviceByService {
          id
          title
        }
      }
    }
  }
`;

export const addEntitiesToAppointment = gql`
  mutation addEntitiesToAppointment(
    $data: [appointment_entity_insert_input!]!
  ) {
    insert_appointment_entity(objects: $data) {
      affected_rows
      returning {
        id
        start
        end
        buffer
        employee
        resource: resource_id
        option: pricing {
          id
          label
          price
        }
        subOption: pricingByPricingSubOption {
          id
          label
          price
        }
        service: serviceByService {
          id
          title
          category: categoryByCategory {
            id
            name
          }
        }
        status: appointment_status {
          value: status
        }
      }
    }
  }
`;

export const getAppointmentStatuses = gql`
  query getAppointmentStatuses {
    appointment_status {
      name
      status
    }
  }
`;

export const appointmentFeedback = gql`
  query getFeedback($id: uuid!) {
    feedback(where: { appointment: { _eq: $id } }) {
      id
      content
      employeeData {
        id
        name: given_name
      }
      recommendations {
        id
        product: productByProduct {
          id
          title
          metadata: productMetadata {
            path
          }
          media {
            id
            asset: assetByAsset {
              id
              url
              type: assetType {
                value
              }
              updatedAt
              createdAt
            }
          }
          pricing {
            id
            createdAt: created_at
            updatedAt: updated_at
            data: pricingByPricing {
              id
              label
              price
              type: pricing_type {
                value: type
              }
              options {
                id
                data: pricingByOption {
                  label
                  price
                }
              }
            }
          }
        }
        service: serviceByService {
          id
          title
          metadata: serviceMetadata {
            path
          }
          media {
            id
            asset: assetByAsset {
              id
              url
              type: assetType {
                value
              }
              updatedAt
              createdAt
            }
          }
          pricing {
            id
            data: pricingByPricing {
              id
              label
              price
              attributes
              discount
              was
              active
              createdAt: created_at
              updatedAt: updated_at
              type: pricing_type {
                value: type
              }
              options {
                id
                data: pricingByOption {
                  id
                  attributes
                  label
                  price
                  was
                  active
                  discount
                  createdAt: created_at
                  updatedAt: updated_at
                }
              }
            }
          }
        }
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const createAppointmentFeedback = gql`
  mutation createAppointmentFeedback($data: feedback_insert_input!) {
    insert_feedback_one(object: $data) {
      id
      content
      employeeData {
        id
        name: given_name
      }
      recommendations {
        id
        product
        service
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const updateFeedback = gql`
  mutation updateAppointmentFeedback($id: uuid!, $content: String!) {
    update_feedback_by_pk(
      pk_columns: { id: $id }
      _set: { content: $content }
    ) {
      id
      content
      employeeData {
        id
        name: given_name
      }
      recommendations {
        id
        product
        service
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getAppointmentRecommendations = gql`
  query getRecommendation($id: uuid!) {
    recommendation(where: { appointment_id: { _eq: $id } }) {
      id
      product
      service
      employee: user_id
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getFormsById = gql`
  query FormById($id: uuid!) {
    form: form_by_pk(id: $id) {
      id
      title
      content
      createdAt
      updatedAt
      formQuestions(order_by: { order: asc }) {
        id
        order
        properties
        question: questionByQuestion {
          id
          label
          type
          properties
        }
      }
    }
    service_form(where: { form: { _eq: $id } }) {
      id
      form
      service: serviceByService {
        id
        title
        subtitle
      }
    }
  }
`;

export const getResponsesForForms = gql`
  query getResponses($id: uuid!, $user: uuid!) {
    form_response(
      order_by: { updatedAt: desc }
      limit: 1
      where: { form: { _eq: $id }, user: { _eq: $user } }
    ) {
      id
      form
      complete
      responses
      asset
      createdAt
      updatedAt
      user: userByUser {
        fullName: full_name
        id
        email
        name: given_name
        surname: family_name
        phoneNumber: contact_number
      }
    }
  }
`;

export const updateFormResponses = gql`
  mutation updateResponses($id: uuid!, $responses: jsonb!) {
    update_form_response_by_pk(
      pk_columns: { id: $id }
      _set: { responses: $responses }
    ) {
      id
      asset
      complete
      responses
      form
      appointment
      createdAt
      updatedAt
    }
  }
`;

export const completeForm = gql`
  mutation updateResponses($id: uuid!, $complete: Boolean!) {
    update_form_response_by_pk(
      pk_columns: { id: $id }
      _set: { complete: $complete }
    ) {
      id
      asset
      complete # we will trigger PDF generation on this being changed to true in DB
      responses
      form
      appointment
      createdAt
      updatedAt
    }
  }
`;

export const completedForms = gql`
  query CompletedDocs($id: uuid!) {
    completed: form_response(
      where: {
        _and: [{ user: { _eq: $id } }, { asset: {} }, { complete: true }]
      }
    ) {
      id
      form: formByForm {
        id
        title
      }
      updatedAt
      asset
    }
  }
`;

export const appointmentsForUser = gql`
  query AppointmentsForEmployee($id: uuid!, $month: Int, $year: Int) {
    appointments: appointment(
      where: {
        _and: [
          { status: { _neq: pending } }
          {
            entities: {
              _and: [
                { employee: { _eq: $id } }
                { month: { _eq: $month } }
                { year: { _eq: $year } }
              ]
            }
          }
        ]
      }
    ) {
      id
      client: userByUser {
        id
        name: given_name
        surname: family_name
        email
        phoneNumber: contact_number
      }
      services: entities(
        where: {
          _and: [
            { employee: { _eq: $id } }
            { month: { _eq: $month } }
            { year: { _eq: $year } }
          ]
        }
      ) {
        id
        start
        end
        status
        resource: resource_id
        option: pricing {
          id
          label
          attributes
          price
          active
        }
        subOption: pricingByPricingSubOption {
          id
          label
          attributes
          price
          active
        }
        service: serviceByService {
          id
          title
        }
      }
    }
  }
`;

export const updateAppointment = gql`
  mutation updateAppointment($id: uuid!, $metadata: jsonb!) {
    update_appointment_by_pk(
      pk_columns: { id: $id }
      _set: { metadata: $metadata }
    ) {
      id
      metadata
    }
  }
`;

export const getAppointmentReports = gql`
  query AppointmentReports($id: uuid!) {
    reports: appointment_report(
      order_by: { created_at: desc }
      where: { appointment: { user: { _eq: $id } } }
    ) {
      id
      status
      type
      user_id
      appointment_id
      appointment_entity_id
      content
      entity {
        id
        start
        status
        service: serviceByService {
          id
          title
          category: categoryByCategory {
            id
            name
          }
        }
      }
      notes(order_by: { created_at: desc }) {
        id
        note: noteByNote {
          id
          title
          content
          user
          createdAt: created_at
          updatedAt: updated_at
        }
      }
      assets(
        where: { assetByAsset: { archived: { _eq: false } } }
        order_by: { created_at: desc }
      ) {
        id
        asset: assetByAsset {
          id
          level
          url
          type
          props
          createdAt
          updatedAt
        }
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getAppointmentSessionReport = gql`
  query AppointmentSessionReport($appointmentId: uuid!, $entityId: uuid!) {
    reports: appointment_report(
      order_by: { created_at: desc }
      where: {
        _and: [
          { appointment_id: { _eq: $appointmentId } }
          { appointment_entity_id: { _eq: $entityId } }
        ]
      }
    ) {
      id
      status
      type
      user_id
      content
      appointment_id
      appointment_entity_id
      notes {
        id
        note: noteByNote {
          id
          title
          content
          user
          createdAt: created_at
          updatedAt: updated_at
        }
      }
      assets(
        where: { assetByAsset: { archived: { _eq: false } } }
        order_by: { created_at: desc }
      ) {
        id
        asset: assetByAsset {
          id
          level
          url
          type
          props
          createdAt
          updatedAt
        }
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const createAppointmentReport = gql`
  mutation CreateAppointmentReport($data: appointment_report_insert_input!) {
    insert: insert_appointment_report_one(object: $data) {
      id
    }
  }
`;

export const updateAppointmentReport = gql`
  mutation UpdateAppointmentReport(
    $id: uuid!
    $data: appointment_report_set_input!
  ) {
    update: update_appointment_report_by_pk(
      pk_columns: { id: $id }
      _set: $data
    ) {
      id
    }
  }
`;

export const addReportAsset = gql`
  mutation AddReportAsset($data: appointment_asset_insert_input!) {
    result: insert_appointment_asset_one(object: $data) {
      id
      asset: assetByAsset {
        id
        level
        url
        type
        props
        createdAt
        updatedAt
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const removeReportAsset = gql`
  mutation RemoveReportAsset($id: uuid!) {
    result: delete_appointment_asset_by_pk(id: $id) {
      id
    }
  }
`;

export const newAppointmentNotification = gql`
  mutation NewAppointmentNotification(
    $data: appointment_notification_insert_input!
  ) {
    result: insert_appointment_notification_one(object: $data) {
      id
    }
  }
`;

export const getAppointmentAssetsByUser = gql`
  ${Asset}
  ${Payment}
  ${PaymentItem}
  ${Note}
  ${Client}
  ${Response}
  ${Form}
  query AppointmentById($id: uuid!) {
    appointment: appointment(where: { user: { _eq: $id } }) {
      id
      createdAt: created_at
      updatedAt: updated_at
      photos: appointment_assets {
        id
        asset: assetByAsset {
          ...Asset
        }
      }
    }
  }
`;
