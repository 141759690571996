import type { CallRequest } from 'interfaces';
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { prepareImage } from 'utils/image';

import { useSession } from './session';

export interface UIContextValue {
  call: boolean;
  callData: any;
  toast: boolean;
  checkout: boolean;
  checkoutData: any;
  block: boolean;
  panelData: any;
  toastData: any;
  blockData: any;
  order: boolean;
  orderId: string;
  image: any | null;
  fullScreen: any | null;
  protected: boolean;
  imageSet: any[];
  imageSetIndex: number;
  panel: boolean;
  detail: boolean;
  detailType: string | null;
  detailData: any;
  type: any;
  search: boolean;
  fauxItem: any;
  detailHandle: boolean;
  setPanel: (panel: boolean, type: any, data?: any) => void;
  setDetail: (
    detail: boolean,
    detailData?: any,
    detailType?: string | null
  ) => void;
  setToast: (toast: boolean, toastData?: any) => void;
  setBlocking: (block: boolean, blockData?: any) => void;
  setOrder: (show: boolean, orderId: string | null) => void;
  setSearch: (term: boolean) => void;
  setImage: (set?: string[]) => void;
  setFullScreen: (data: any | null) => void;
  setProtected: (pin?: string | boolean) => void;
  setImageIndex: (index: number) => void;
  setFauxItem: (data: any | null) => void;
  setCall: (call: boolean, data: CallRequest | null) => void;
  setCheckout: (show: boolean, data?: any) => void;
}

const initialState = {
  panel: false,
  panelData: null,
  toast: false,
  toastData: null,
  block: false,
  blockData: null,
  image: null,
  fullScreen: null,
  protected: false,
  detail: false,
  detailType: null,
  detailData: null,
  type: null,
  search: false,
  fauxItem: null,
  detailHandle: false,
  call: false,
  callData: null,
  checkout: false,
  checkoutData: null,
  order: false,
  orderId: null,
};

export const UIContext = createContext(initialState as UIContextValue);

interface State {
  panelData: any;
  toast: boolean;
  toastData: any;
  order: boolean;
  orderId: string;
  block: boolean;
  blockData: any;
  image: string | null;
  fullScreen: string | null;
  protected: boolean;
  imageSet: string[];
  imageSetIndex: number;
  panel: boolean;
  detail: boolean;
  detailHandle: boolean;
  detailType: string | null;
  detailData: null;
  type: any;
  fauxItem: any;
  search: boolean;
  call: boolean;
  callData: CallRequest;
  checkout: boolean;
  checkoutData: any;
}

export const UI: FC = ({ children }: { children: React.ReactNode }) => {
  const { token } = useSession();
  const [state, setState] = useState<State>({
    ...initialState,
    imageSet: [],
    imageSetIndex: 0,
    protected: (() => {
      const t = localStorage.getItem('mg.protected');
      if (t) {
        return Boolean(t);
      }
      return false;
    })(),
  });

  const setPanel = useCallback(
    (panel: boolean, type: any, data?: any) => {
      if (!panel) {
        setState((s) => {
          setTimeout(() => {
            setState((s) => ({
              ...s,
              panel,
              type,
              panelData: data,
              search: false,
            }));
          }, 600);
          return {
            ...s,
            panel: false,
          };
        });
      } else {
        setState((s) => ({
          ...s,
          panel,
          type,
          panelData: data,
          search: false,
        }));
      }
    },
    [setState]
  );

  const setDetail = useCallback(
    (
      detail: boolean,
      detailData: any = null,
      detailType: string | null = null
    ) => {
      if (!detail) {
        setState((s) => {
          setTimeout(() => {
            setState((s) => ({
              ...s,
              detail: false,
              detailData,
              detailType,
              search: false,
            }));
          }, 550);
          return {
            ...s,
            detailHandle: false,
          };
        });
      } else {
        setState((s) => {
          return {
            ...s,
            detailHandle: true,
            detail,
            detailData,
            detailType,
            search: false,
          };
        });
      }
    },
    [setState]
  );

  const setToast = useCallback(
    (toast: boolean, toastData?: any) => {
      setState((s) => ({ ...s, toast, toastData }));
    },
    [setState]
  );

  const setBlocking = useCallback(
    (block: boolean, blockData?: any) => {
      setState((s) => ({ ...s, block, blockData }));
    },
    [setState]
  );

  const setSearch = useCallback(
    (term: boolean) => {
      setState((s) => ({ ...s, search: term }));
    },
    [setState]
  );

  const setImage = useCallback(
    (src?: any) => {
      if (!src || src.length === 0) {
        return setState((s) => ({ ...s, imageSet: [], imageSetIndex: 0 }));
      }
      console.log(src);
      setState((s) => ({
        ...s,
        imageSet: src.map((x) => ({
          ...x,
          url: prepareImage(x.asset.url, token),
        })),
      }));
    },
    [token]
  );

  const setImageIndex = useCallback(
    (index: number) => {
      return setState((s) => ({ ...s, imageSetIndex: index }));
    },
    [setState]
  );

  const setFullScreen = useCallback(
    (data: any | null) => {
      setState((s) => ({ ...s, fullScreen: data }));
    },
    [setState]
  );

  const setProtected = useCallback(
    (pin?: string) => {
      if (pin) {
        localStorage.setItem('mg.protected', pin);
        setState((s) => ({ ...s, protected: true }));
      } else {
        setState((s) => ({ ...s, protected: false }));
      }
    },
    [setState]
  );

  const setFauxItem = useCallback(
    (data: any | null) => {
      setState((s) => ({ ...s, fauxItem: data }));
    },
    [setState]
  );

  const setCall = useCallback(
    (call: boolean, callData: CallRequest | null) => {
      setState((s) => ({ ...s, call, callData }));
    },
    [setState]
  );

  const setCheckout = useCallback(
    (show: boolean, data: any = null) => {
      setState((s) => ({ ...s, checkout: show, checkoutData: data }));
    },
    [setState]
  );

  const setOrder = useCallback(
    (show: boolean, orderId: string | null) => {
      setState((s) => ({ ...s, order: show, orderId }));
    },
    [setState]
  );

  const value: UIContextValue = useMemo(
    () => ({
      ...state,
      setPanel,
      setSearch,
      setToast,
      setDetail,
      setImage,
      setFullScreen,
      setFauxItem,
      setProtected,
      setBlocking,
      setCheckout,
      setImageIndex,
      setCall,
      setOrder,
    }),
    [
      state,
      setPanel,
      setSearch,
      setToast,
      setDetail,
      setImage,
      setFullScreen,
      setFauxItem,
      setProtected,
      setBlocking,
      setImageIndex,
      setCall,
      setCheckout,
      setOrder,
    ]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const UIProvider = UI;

export const useUI = () => useContext(UIContext);

export default UIProvider;
