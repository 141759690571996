import { useSubscription as uS, useMutation } from '@apollo/client';
import { ServerIcon } from '@heroicons/react/24/outline';
import { captureException } from '@sentry/nextjs';
import { Call, Device } from '@twilio/voice-sdk';
import { TeamMember } from 'interfaces';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  addCall,
  getAllCallSessions,
  getCalls,
  insertCallSession,
  updateCallById,
  updateCallSession,
} from 'queries/calls';

import { useUI } from 'providers/ui';

import { useBase } from './base';
import { useOrganization } from './organization';
import { useSession } from './session';

export interface CallContextValue {
  call: any;
  incomingCall: Call;
  active: Call;
  callSessions: any;
  device: Device;
  target: any;
  muted: any;
  status: any;
  callStarted: boolean;
  startCall: (number: string, lead?: any) => Promise<void>;
  handleIncomingCall: (target: any) => void;
  hangupCall: () => void;
  mute: (mute: boolean) => void;
}

type State = {
  token: string;
  identity: string;
  muted: boolean;
  status: string;
  active: Call;
  incomingCall: Call;
  callStarted: boolean;
  target: any;
  member: TeamMember;
};

const initialState = {};

export const CallContext = createContext(initialState as CallContextValue);

const resetStatus = () => {
  return {
    muted: false,
    active: null,
    incomingCall: null,
    callStarted: false,
    target: null,
  };
};

export function SP({ children }) {
  const [count, setCount] = useState(null);
  const [state, setState] = useState<State>({
    token: null,
    identity: null,
    muted: false,
    status: null,
    active: null,
    callStarted: false,
    target: null,
    member: null,
    incomingCall: null,
  });

  const callRef = useRef<Call>(null);
  const deviceRef = useRef<Device>(null);
  const { clients } = useBase();
  const { setCall } = useUI();
  const [createCall] = useMutation(addCall);
  const [updateCallEmployee] = useMutation(updateCallById);
  const [changeStatus] = useMutation(updateCallSession);
  const [insertSession] = useMutation(insertCallSession);
  const ui = useUI();
  const { user, claims } = useSession();
  const org = useOrganization();
  const member = useMemo(
    () => user && org.teamMember(claims['x-hasura-user-id']),
    [claims, org, user]
  );
  const supported = 'Notification' in window;

  const {
    error: callsError,
    data: callsData,
    loading: callsLoading,
  } = uS(getCalls, {
    onSubscriptionData: (e) => {
      setCount(e.subscriptionData.data.call.length);
      try {
        if (count && count < e.subscriptionData.data.call.length) {
          const item = e.subscriptionData.data.call[0];
          const img = '/android-chrome-192x192.png';
          const text = item.user
            ? `${item.user.full_name} (${item.payload.text})`
            : item.payload.text;
          const notification = supported
            ? new Notification('Managed', { body: text, icon: img })
            : null;
        }
      } catch (err) {
        console.log(err);
        captureException(err);
      }
    },
  });

  const {
    error: callSessionsError,
    data: callSessionsData,
    loading: callSessionsLoading,
  } = uS(getAllCallSessions);

  useEffect(() => {
    if (supported && Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (member && !deviceRef.current) {
      startupClient();
      setState((s) => ({ ...s, member }));
    }
  }, [member]);

  const startCall = useCallback(
    async (number: string, lead?: any) => {
      if (deviceRef?.current) {
        console.log('Starting call', number);

        const params = {
          To: number,
        };

        if (deviceRef?.current) {
          console.log(`Attempting to call ${number} ...`);

          const lookup = clients.find((x) => x.phoneNumber === number);
          const call = await deviceRef?.current?.connect({ params });
          callRef.current = call;

          const res = await createCall({
            variables: {
              data: {
                type: 'outgoing',
                user_id: lookup ? lookup.id : null,
                lead_id: lead ? lead.id : null,
                payload: {
                  number: `${params.To}`,
                  callSid: call.parameters.CallSid,
                  ...call.parameters,
                  outboundConnectionId: call.outboundConnectionId,
                },
              },
            },
          });

          setState((s) => ({ ...s, active: call }));
          call.on('accept', async () => {
            setState((s) => ({ ...s, on: true, status: 'Connected' }));
            const ures = await updateCallEmployee({
              variables: {
                id: res.data.insert_call_one.id,
                data: {
                  payload: {
                    number: `${params.To}`,
                    callSid: call.parameters.CallSid,
                    ...call.parameters,
                    outboundConnectionId: call.outboundConnectionId,
                  },
                },
              },
            });

            await changeStatus({
              variables: {
                employee: state.member.user.id,
                data: {
                  call_id: res.data.insert_call_one.id,
                  status: 'Busy',
                },
              },
            });
          });
          call.on('disconnect', () => {
            hangupCall();
          });
          call.on('cancel', () => {
            console.log('Call cancel');
            hangupCall();
          });
          call.on('reject', () => {
            console.log('Call Rejection');
            hangupCall();
          });
          call.on('mute', (isMuted) => {
            console.log('Call mute', isMuted);
            setState((s) => ({ ...s, muted: isMuted }));
          });
        }
      } else {
        console.log('Unable to make call. Device Not Ready.');
      }
    },
    [clients, createCall, deviceRef]
  );

  const incomingHandler = useCallback(
    (call: Call, device: Device) => {
      return () => {
        if (state.callStarted) {
          return call.reject();
        }
        if (!state.callStarted) {
          setState((s) => ({ ...s, incomingCall: call }));
        }
        call.on('cancel', () => {
          console.log('CANCELLED CALL');
          hangupCall();
        });
        call.on('disconnect', () => {
          console.log('DISCONNECTED CALL');
          hangupCall();
        });
        call.on('reject', () => {
          console.log('REJECTED CALL');
          resetStatus();
        });
      };
    },

    [state]
  );

  const handleIncomingCall = useCallback(
    async (call: Call) => {
      const CallSid = call.customParameters.get('Parent');
      const record = callsData.call.find(
        (x) => x.payload.callSid === CallSid || x.payload.sid === CallSid
      );

      call.accept();

      call.on('mute', (isMuted) => {
        console.log('mute', isMuted);
        setState((s) => ({ ...s, muted: isMuted }));
      });

      call.on('accept', (e) => {
        callRef.current = call;
        setState((s) => ({
          ...s,
          callStarted: true,
          active: call,
          status: 'Connected',
        }));
      });

      if (record) {
        console.log('updating', record);
        await changeStatus({
          variables: {
            employee: member?.user?.id,
            data: {
              call_id: record.id,
              status: 'Busy',
            },
          },
        }),
          await updateCallEmployee({
            variables: {
              id: record.id,
              data: {
                employee_id: member?.user?.id,
              },
            },
          });
      }
    },
    [callsData, changeStatus, member, updateCallEmployee]
  );

  const mute = useCallback(
    (mute: boolean) => {
      if (callRef.current) {
        callRef.current?.mute(mute);
      }
    },
    [callRef]
  );

  const hangupCall = useCallback(async () => {
    if (callRef.current) {
      callRef.current.disconnect();
    }

    if (state.member && state.member.user) {
      await changeStatus({
        variables: {
          employee: state.member.user.id,
          data: {
            call_id: null,
            status: 'Available',
          },
        },
      });
    }

    callRef.current = null;

    setState((s) => ({
      ...s,
      ...resetStatus(),
    }));
    setCall(false, null);
  }, [callRef, state, setCall, changeStatus]);

  async function startupClient() {
    try {
      const currentKey = localStorage.getItem('mgd.callstatus');
      let res;
      const data = await fetch('/api/call', {
        method: 'POST',
        body: JSON.stringify({
          id: member.user.id,
        }),
      });
      res = await data.json();

      const device = new Device(res.token, {
        logLevel: 0,
        // @ts-ignore
        codecPreferences: ['opus', 'pcmu'],
        edge: ['dublin', 'frankfurt'],
        tokenRefreshMs: 10000,
        closeProtection: true,
      });

      device.on('registered', () => {
        console.log('Twilio.Device Ready to make and receive calls!');
      });

      device.on('error', (error) => {
        console.log('Twilio.Device Error: ' + error.message);
      });

      device.on('incoming', async (call) => {
        console.log('INCOMING', call);
        incomingHandler(call, device)();
      });

      device.on('disconnect', async (connection) => {
        console.log('DISCONNECTED', connection);
        hangupCall();
      });

      device?.register();

      deviceRef.current = device;
      setState((s) => ({ ...s, device }));
      deviceRef.current?.on('tokenWillExpire', async () => {
        const data = await fetch('/api/call', {
          method: 'POST',
          body: JSON.stringify({
            id: member.user.id,
          }),
        });
        res = await data.json();
        deviceRef.current.updateToken(res.token);
      });

      if (member.user.call_session) {
        const result = await changeStatus({
          variables: {
            employee: member?.user?.id,
            data: {
              status: currentKey || 'Offline',
              token: res.token,
              call_id: null,
            },
          },
        });
      }

      if (!member.user.call_session) {
        const result = await insertSession({
          variables: {
            data: {
              status: currentKey || 'Offline',
              employee_id: member?.user?.id,
              token: res.token,
            },
          },
        });
      }

      if (deviceRef.current) {
        ui.setToast(true, {
          type: 'success',
          title: `Phone Status`,
          message: `Status Set To ${currentKey || 'Offline'}`,
        });
      }

      setState((s) => ({
        ...s,
        ...res,
        device,
      }));
    } catch (err) {
      console.log(err);
      captureException(err);
    }
  }

  const value = useMemo(
    () => ({
      ...state,
      device: deviceRef.current,
      call: callsData && callsData.call,
      callSessions: callSessionsData && callSessionsData.call_session,
      startCall,
      callStarted: state.callStarted,
      handleIncomingCall,
      hangupCall,
      startupClient,
      mute,
    }),
    [
      callsData,
      callSessionsData,
      state.callStarted,
      handleIncomingCall,
      hangupCall,
      mute,
      startCall,
      state,
    ]
  );

  return (
    // @ts-ignore
    <CallContext.Provider value={value}>{children}</CallContext.Provider>
  );
}

export const CallProvider = SP;

export const useCall = () => useContext(CallContext);

export default CallProvider;
