import { gql } from '@apollo/client';

export const Client = gql`
  fragment Client on user {
    id
    cognito_id
    title
    name: given_name
    surname: family_name
    fullName: full_name
    email
    gender
    birthday: date_of_birth
    phoneNumber: contact_number
    avatar
    points
    account: stripe_id
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const AppointmentFeedbackDetail = gql`
  fragment AppointmentFeedbackDetail on appointment_feedback {
    id
    createdAt: created_at
    updatedAt: updated_at
    data
    appointmentEntity {
      service: serviceByService {
        title
      }
      subPricing: pricingByPricingSubOption {
        price
      }
      pricing: pricing {
        price
      }
      start
      end
    }
    appointment {
      id
      appointmentId: id
      bookedBy {
        id
        name: given_name
        surname: family_name
        fullName: full_name
      }
      updatedBy: updated_by
      metadata
      source
      status
      client: userByUser {
        id
        name: given_name
        surname: family_name
        fullName: full_name
      }
      services: entities {
        id
        start
        end
        buffer
        employee
        resource: resource_id
        option: pricing {
          id
          label
          price
          active
        }
        subOption: pricingByPricingSubOption {
          id
          label
          price
          active
        }
        service: serviceByService {
          id
          title
        }
      }
    }
  }
`;

export const AppointmentFeedbackList = gql`
  fragment AppointmentFeedbackList on appointment_feedback {
    id
    createdAt: created_at
    updatedAt: updated_at
    data
    employee {
      id
      name: given_name
      surname: family_name
    }
    appointmentEntity {
      service: serviceByService {
        title
      }
      subPricing: pricingByPricingSubOption {
        price
      }
      pricing: pricing {
        price
      }
      start
      end
    }
    appointment {
      id
      status
      appointmentId: id
      bookedBy {
        id
        name: given_name
        surname: family_name
        email
      }
      client: userByUser {
        id
        name: given_name
        surname: family_name
      }
    }
  }
`;

export const DiscountCode = gql`
  fragment DiscountCode on discount_code {
    id
    code
    type
    value
    quantity
    expires
    active
    options
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const Complaints = gql`
  fragment Complaints on complaints {
    id
    created_at
    updated_at
    title
    complaint
    resolution
    user_id
    employee_id
    assignee_id
    assignee {
      id
      user {
        id
        given_name
        family_name
        full_name
      }
    }
    user {
      id
      full_name
    }
    employee {
      id
      given_name
      family_name
      full_name
      user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
        asset: assetByAsset {
          id
          type
          url
        }
      }
    }
    notes {
      id
      note {
        id
        title
        created_at
        updated_at
        content
      }
    }
    calls {
      id
      complaint_id
      call_id
    }
  }
`;

export const Vendor = gql`
  fragment Vendor on vendor {
    id
    name
    description
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const Expense = gql`
  fragment Expense on expense {
    id
    amount
    expense_number
    employee_id
    user: employee {
      id
      memberAvatar: user_assets {
        asset: assetByAsset {
          id
          url
        }
      }
      name: given_name
    }
    explanation
    includes_vat
    vendor_id
    vendor {
      id
      name
      description
    }
    expense_assets {
      asset {
        url
        id
      }
    }
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const Payment = gql`
  fragment Payment on payment {
    id
    amount
    discount
    discountExplanation: discount_explanation
    user
    status
    createdAt: created_at
    updatedAt: updated_at
    transaction_id
    method
    employee
    metadata
  }
`;

export const PaymentItem = gql`
  fragment PaymentItem on payment_item {
    id
    amount
    service_id
    pricing_option
    pricing_sub_option
    product_sale_id
  }
`;

export const Asset = gql`
  fragment Asset on asset {
    id
    url
    level
    properties: props
    type: assetType {
      value
    }
    who
    updatedBy: updated_by
    createdAt
    updatedAt
  }
`;

export const Note = gql`
  fragment Note on note {
    id
    title
    content
    user
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const Address = gql`
  fragment Address on address {
    id
    data
    createdAt
    updatedAt
  }
`;

export const Block = gql`
  fragment Block on appointment_block {
    id
    start
    end
    info
    resourceId: resource_id
    employee: user
  }
`;

export const Call = gql`
  fragment Call on call {
    id
    due
    organization
    status
    payload
    user_id
    viewed
    type
    employee {
      id
      name: given_name
    }
    createdAt: created_at
    updatedAt: updated_at
    notes_aggregate {
      aggregate {
        count
      }
    }
    contacts_aggregate {
      aggregate {
        count
      }
    }
    conversions_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const CallContact = gql`
  fragment CallContact on call_contact {
    id
    message
    subject
    type
    source
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const Product = gql`
  fragment Product on product {
    id
    title
    subtitle
    minimum_quantity
    gtin
    sku
    archived
    cost_price
    category: categoryByCategory {
      id
      name
    }
    content
    metadata: productMetadata {
      id
      title
      description
      path
    }
    inventory: product_inventories(order_by: { created_at: desc }, limit: 1) {
      id
      quantity
      updated_at
    }
    media {
      id
      asset: assetByAsset {
        id
        url
        type: assetType {
          value
        }
        updatedAt
        createdAt
      }
    }
    pricing {
      id
      createdAt: created_at
      updatedAt: updated_at
      data: pricingByPricing {
        id
        label
        price
        type: pricing_type {
          value: type
        }
        options {
          id
          data: pricingByOption {
            label
            price
          }
        }
        was
      }
    }
    createdAt: created_at
    updatedAt: updated_at
    cost_price
    stripe_id
  }
`;

export const CallConversion = gql`
  fragment CallConversion on call_conversion {
    id
    call
    value
    createdAt: created_at
  }
`;

export const Form = gql`
  fragment Form on form {
    id
    title
    content
    createdAt
    updatedAt
    archived
  }
`;

export const FormQuestion = gql`
  fragment FormQuestion on form_question {
    id
    order
    properties
  }
`;

export const Question = gql`
  fragment Question on question {
    id
    label
    type
    properties
  }
`;

export const Response = gql`
  fragment Response on form_response {
    id
    complete
    asset
    responses
    form
    appointment
    createdAt
    updatedAt
  }
`;

export const Lead = gql`
  fragment Lead on lead {
    id
    due
    content
    metadata
    source
    userId: user
    status
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const LeadContact = gql`
  fragment LeadContact on lead_contact {
    id
    message
    subject
    type
    source
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const LeadConversion = gql`
  fragment LeadConversion on lead_conversion {
    id
    value
    createdAt: created_at
  }
`;

export const Appointment = gql`
  fragment Appointment on appointment {
    id
    appointmentId: id
    bookedBy: booked_by
    updatedBy: updated_by
    metadata
    source
    status: appointmentStatus {
      value: status
    }
  }
`;

export const AppointmentEntity = gql`
  fragment AppointmentEntity on appointment_entity {
    id
    start
    end
    employee
    resource: resource_id
    resourceName: resource {
      name
    }
    who
    buffer
    confirmed_by
    appointmentId: appointment
    updatedBy: updated_by
    service: serviceByService {
      id
      title
      pricing(where: { pricingByPricing: { active: { _eq: true } } }) {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          active
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              active
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
    }
    option: pricing {
      id
      label
      price
      attributes
      type
      active
      discount
    }
    subOption: pricingByPricingSubOption {
      id
      label
      price
      attributes
      type
      active
      discount
    }
    status: appointment_status {
      value: status
    }
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const Order = gql`
  fragment Order on order {
    id
    fullTotal: data(path: "checkout.displayTotal")
    total: data(path: "checkout.discountTotal")
    discountValue: data(path: "checkout.discountValue")
    discount: data(path: "checkout.discount")
    products: data(path: "checkout.items.products")
    shipping: data(path: "checkout.items.shipping")
    status
    createdAt: created_at
    updatedAt: updated_at
    totalAmount: total
    currentTotal: current_total
    remaining
    currentValue: current_value
    data
    productSales {
      id
      price
      quantity
      product {
        id
        title
        subtitle
        minimum_quantity
        gtin
        sku
        archived
        cost_price
        category: categoryByCategory {
          id
          name
        }
        content
        metadata: productMetadata {
          id
          title
          description
          path
        }
        inventory: product_inventories(
          order_by: { created_at: desc }
          limit: 1
        ) {
          id
          quantity
          updated_at
        }
        media {
          id
          asset: assetByAsset {
            id
            url
            type: assetType {
              value
            }
            updatedAt
            createdAt
          }
        }
        pricing {
          id
          createdAt: created_at
          updatedAt: updated_at
          data: pricingByPricing {
            id
            label
            price
            type: pricing_type {
              value: type
            }
            options {
              id
              data: pricingByOption {
                label
                price
              }
            }
            was
          }
        }
        createdAt: created_at
        updatedAt: updated_at
        cost_price
        stripe_id
      }
    }
    payments {
      id
      amount
      method
      employee
      status
      order_id
      transactionId: transaction_id
      createdAt: created_at
      credits {
        id
        credit {
          id
          amount
          explanation
          employee_id
        }
      }
      discounts {
        id
        discount {
          id
          code
          type
          value
        }
      }
      paymentItems {
        id
        amount
        productSale {
          id
          price
          quantity
          product {
            id
            title
          }
        }
        service {
          id
          title
        }
        pricing {
          id
          label
        }
        pricingOption {
          id
          label
        }
      }
    }
    appointments {
      id
      status
      employee: bookedBy {
        id
        fullName: full_name
        createdAt: created_at
        updatedAt: updated_at
      }
      payments {
        id
        paymentByPayment {
          id
          status
          amount
          credits {
            id
            credit {
              amount
              explanation
            }
          }
        }
      }
      entities(order_by: { start: desc }) {
        ...AppointmentEntity
      }
    }
    user: userByUser {
      id
      fullName: full_name
      phoneNumber: contact_number
      email
      given_name
      family_name
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const Refund = gql`
  fragment Refund on refund {
    id
    amount
    status
    explanation
    employeeId: employee_id
    paymentId: payment_id
    createdAt: created_at
    updatedAt: updated_at
    user: employee {
      id
      memberAvatar: user_assets {
        asset: assetByAsset {
          id
          url
        }
      }
      name: given_name
    }
  }
`;
