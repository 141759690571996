import { SVGProps } from 'react';

import LoaderIcon from '../../assets/loader.svg';

interface Props extends SVGProps<SVGElement> {
  styles?: string;
}

const cls =
  'flex flex-1 flex-col h-full flex-grow p-8 px-4 items-center justify-center';

export const Loader = ({
  styles = null,
  stroke = '#a189ff',
  width = 44,
  height = 44,
}: Props) => {
  return (
    <section className={styles || cls}>
      <div className="flex min-h-full justify-center">
        <LoaderIcon width={width} height={height} stroke={stroke} />
      </div>
    </section>
  );
};

export default Loader;
