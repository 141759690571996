import { gql } from '@apollo/client';

import { Call, CallContact, CallConversion, Client, Note } from './fragments';

export const addCall = gql`
  mutation Call($data: call_insert_input!) {
    insert_call_one(object: $data) {
      id
    }
  }
`;

export const getOutBoundCallsByUserID = gql`
  query getOutBoundCallsByUserID($userID: uuid!) {
    call(where: { user_id: { _eq: $userID } }) {
      id
      created_at
      updated_at
      payload
      user_id
      status
      viewed
      due
    }
  }
`;

export const getCalls = gql`
  ${Call}
  ${Client}
  subscription GetCalls {
    call(limit: 100, order_by: { created_at: desc }) {
      ...Call
      user {
        ...Client
        labels: user_labels(order_by: { created_at: desc }, limit: 1) {
          description
          color
          label {
            name
          }
        }
      }
    }
  }
`;

export const getAllCallSessions = gql`
  subscription getAllCallSessions {
    call_session(
      order_by: { status: asc }
      where: { employee: { organization_member: { active: { _eq: true } } } }
    ) {
      id
      status
      call_id
      call {
        id
        payload
        user {
          id
          given_name
          family_name
          full_name
        }
      }
      employee {
        id
        given_name
        family_name
        full_name
        user_assets {
          assetByAsset {
            user_assets {
              assetByAsset {
                url
              }
            }
          }
        }
        member: organization_members {
          id
          job_title
          active
        }
      }
    }
  }
`;

export const getCallById = gql`
  ${Note}
  ${Call}
  ${Client}
  ${CallContact}
  ${CallConversion}
  query getCallById($id: uuid!) {
    call: call_by_pk(id: $id) {
      ...Call
      user {
        ...Client
        labels: user_labels(order_by: { created_at: desc }) {
          label {
            id
            name
          }
        }
        complaints {
          id
          created_at
          updated_at
          title
          complaint
          resolution
          assignee {
            id
            user {
              id
              name: given_name
              surname: family_name
              fullName: full_name
              assets: user_assets {
                id
                assetByAsset {
                  id
                  url
                }
              }
            }
          }
        }
        appointments(order_by: { updated_at: desc }) {
          id
          entities(limit: 1, order_by: { start: asc_nulls_last }) {
            start
            service: serviceByService {
              title
            }
          }
        }
      }
      notes_aggregate {
        aggregate {
          count
        }
      }
      notes(order_by: { created_at: desc }) {
        id
        data: note {
          ...Note
        }
      }
      messages: contacts(order_by: { created_at: desc }) {
        ...CallContact
        user {
          id
          name: given_name
        }
      }
      contacts_aggregate {
        aggregate {
          count
        }
      }
      conversions: conversions {
        ...CallConversion
        user: user {
          id
          name: given_name
          surname: family_name
          fullName: full_name
        }
        appointment: appointmentByAppointment {
          entities {
            service: serviceByService {
              title
            }
            employee
            start
            end
          }
        }
      }
      conversions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const updateCallById = gql`
  mutation updateCallById($id: uuid!, $data: call_set_input!) {
    update_call_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
      user_id
      status
      viewed
      employee_id
    }
  }
`;

export const updateCall = gql`
  ${Call}
  mutation updateCall($id: uuid!, $status: String!) {
    update_call_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      ...Call
    }
  }
`;

export const addCallConversion = gql`
  ${CallConversion}
  mutation CallConversion($data: call_conversion_insert_input!) {
    insert_call_conversion_one(object: $data) {
      ...CallConversion
    }
  }
`;

export const addCallNote = gql`
  ${Note}
  mutation CallNote($data: call_note_insert_input!) {
    insert_call_note_one(object: $data) {
      id
      note {
        ...Note
      }
    }
  }
`;

export const addCallContact = gql`
  ${CallContact}
  mutation CallContact($data: call_contact_insert_input!) {
    insert_call_contact_one(object: $data) {
      ...CallContact
    }
  }
`;

export const clientCalls = gql`
  ${Call}
  ${Client}
  query ClientCalls($id: uuid!) {
    call(where: { user_id: { _eq: $id } }, order_by: { created_at: desc }) {
      ...Call
    }
  }
`;
export const updateCallSession = gql`
  mutation updateCallSession($employee: uuid!, $data: call_session_set_input!) {
    update_call_session(
      where: { employee_id: { _eq: $employee } }
      _set: $data
    ) {
      returning {
        id
        status
        employee_id
        call_id
      }
    }
  }
`;

export const getCallSession = gql`
  query getCallSession($employee: uuid!) {
    call_session(where: { employee_id: { _eq: $employee } }, limit: 1) {
      id
      status
    }
  }
`;

export const insertCallSession = gql`
  mutation insertCallSession($data: call_session_insert_input!) {
    insert_call_session_one(object: $data) {
      id
    }
  }
`;
