import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import { Lead, Resource, Service, UserProfile } from 'interfaces';
import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { getAppointmentStatuses } from 'queries/appointments';
import { getClients } from 'queries/clients';
import { getIncomingStatus, getLeads } from 'queries/leads';
import { getResources } from 'queries/resources';
import { getServices } from 'queries/services';

const emptyArray = [];

interface AppointmentStatus {
  name: string;
  status: string;
}

export interface BaseContextValue {
  clients: UserProfile[];
  services: Service[];
  leads: Lead[];
  leadsAggregate: any;
  refetchClients: any;
  resources: Resource[];
  appointmentStatuses: AppointmentStatus[];
  statusValues: { value: string }[];
  loadResources: (options?: QueryLazyOptions<OperationVariables>) => void;
  loadLeads: (options?: QueryLazyOptions<OperationVariables>) => void;
  loadClients: (options?: QueryLazyOptions<OperationVariables>) => void;
  loadServices: (options?: QueryLazyOptions<OperationVariables>) => void;
}

const initialState = {};

export const BaseContext = createContext(initialState as BaseContextValue);

export function BP({ children }: { children: React.ReactNode }) {
  const [loadResources, { data: resources, loading: resourcesLoading }] =
    useLazyQuery<{ resource: Resource[] }>(getResources, {
      fetchPolicy: 'network-only',
    });

  const { data: statusValues } = useQuery<{ status: { value: string }[] }>(
    getIncomingStatus
  );

  const [loadClients, { data: clients, refetch: refetchClients }] =
    useLazyQuery<{
      clients: UserProfile[];
    }>(getClients, {
      fetchPolicy: 'cache-and-network',
    });

  const [loadServices, { data: services, loading: isServicesLoading }] =
    useLazyQuery<{
      service: Service[];
    }>(getServices, {
      fetchPolicy: 'cache-and-network',
    });

  const [
    loadLeads,
    {
      called: leadsCalled,
      error: leadsError,
      data: leadsData,
      loading: leadsLoading,
      refetch: refetchLeads,
    },
  ] = useLazyQuery<{
    leads: Lead[];
    agg?: any;
  }>(getLeads, {
    fetchPolicy: 'cache-and-network',
  });

  const { data: appointmentStatusesData } = useQuery<{
    appointment_status: AppointmentStatus[];
  }>(getAppointmentStatuses);

  useEffect(() => {
    Promise.all([loadClients(), loadResources(), loadServices()]);
  }, []);

  const value: BaseContextValue = useMemo(
    () => ({
      clients: clients ? clients.clients : [],
      services:
        services && services.service && services.service.length > 0
          ? services.service
          : [],
      leads: leadsData ? leadsData.leads : [],
      leadsAggregate: leadsData ? leadsData.agg : {},
      resources: resources ? resources.resource : [],
      statusValues: statusValues ? statusValues.status : [],
      loadClients,
      loadServices,
      loadLeads,
      loadResources,
      refetchClients,
      appointmentStatuses:
        appointmentStatusesData?.appointment_status || emptyArray,
    }),
    [
      clients,
      services,
      leadsData,
      resources,
      statusValues,
      loadClients,
      loadServices,
      loadLeads,
      loadResources,
      refetchClients,
    ]
  );

  return <BaseContext.Provider value={value}>{children}</BaseContext.Provider>;
}

export const BaseProvider = BP;

export const useBase = () => useContext(BaseContext);

export default BaseProvider;
